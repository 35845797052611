<template>
<div>
    <v-row class="ma-4">
        <h1 class="secondary--text">حول المستشفى</h1>
        <v-spacer />
        <v-btn outlined to="/home" class="mr-12 btn">
            <h4>رجوع</h4>
            <v-icon>
                fi fi-rr-arrow-small-left
            </v-icon>
        </v-btn>
    </v-row>
    <v-divider />

    <v-row class="mx-6 mt-12" justify="center" align="center">
        <v-col cols="12" md="6">
            <!-- <v-container>
                <Album />

            </v-container> -->

            <v-container fluid>
                <!-- <v-card style="border-radius: 40px;border: solid 3px #CFD8DC"  outlined> -->
                <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fkwh.iq%2Fvideos%2F545965393929167&show_text=0&width=560" width="100%" height="400" style="border:none;overflow:hidden;" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
                <!-- </v-card> -->
            </v-container>
            <!-- 

<script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script>

            <dotlottie-player src="https://lottie.host/59f8579d-e3e3-434c-8cf9-eeff374f15eb/St6PSK7gtl.json" background="transparent" speed="1" style="width: 600px; height: 600px;" loop autoplay></dotlottie-player> -->
        </v-col>
        <v-col cols="12" md="6">
            <h2 class="primary--text">حول المستشفى</h2>
            <h3 style="text-align: justify;">
                السيدة خديجة الكبرى (عليها السلام) أول مستشفى تخصصي للمرأة في العراق ومنطقة الشرق الأوسط، افتتح بإشراف العتبة الحسينية المقدسة وبذلك انضم إلى مؤسساتها الصحية في هيئة الصحة والتعليم الطبي.

                في عام 2022 تمّ إنشاء مستشفى السيدة خديجة الكبرى (عليها السلام) التخصصي للمرأة وتجهيزه بأحدث الأجهزة الطبية بسعة اجمالية قدرها 202 سريراً. وبذلك أصبحت مستشفى السيدة خديجة الكبرى (عليها السلام) التخصصي للمرأة وجهة صحية للنساء من داخل وخارج العراق،  تمتلك أفضل الخدمات الطبية والكفاءات الطبية العالمية، كما تحرص على توفير خصوصية وخدمات لائقة للنساء، إضافة إلى اعتبار مستشفى السيدة خديجة الكبرى (عليها السلام) التخصصي للمرأة أول مستشفى جامعي تدريبي يوفر التعليم والتدريب في المجالات الصحية المختلفة لطلاب الكليات الطبية والتمريضية والصيدلانية.
            </h3>
        </v-col>
    </v-row>

    <v-row justify="center" align="center" class="mx-auto">
        <v-col cols="12" md="12">
            <div class="white ">
                <v-container fluid>
                    <v-card color="primary" style="border-radius: 30px" dark outlined>
                        <v-form ref="form" v-model="valid">
                            <v-container>
                                <v-row class="text-center mx-4" justify="center" align="center">
                                    <v-col cols="12">
                                        <h1>تواصلي معنا</h1>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field :rules="[required()]" v-model="message.firstName" label="الاسم الاول" filled outlined rounded hide-details="auto" />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field :rules="[required()]" v-model="message.lastName" label="الاسم الثاني" filled outlined rounded hide-details="auto" />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field :rules="[required()]" dir="ltr" v-model="message.phoneNumber" label="رقم الهاتف" filled outlined rounded hide-details="auto" />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-model="message.email" label="البريدالالكتروني" filled outlined rounded hide-details="auto" />
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-textarea :rules="[required()]" v-model="message.details" label="التفاصيل" filled outlined rounded hide-details="auto" />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn x-large rounded class="btn" @click="sendMessage()" :loading="$global.state.loading" :disabled="!valid">
                                            <h3 class="mx-8">ارسال</h3>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-container>
            </div>
        </v-col>

    </v-row>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9198.223534211425!2d44.038545152873915!3d32.59820840370001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15596b005a4a8817%3A0xa37aedd0bd1c589a!2z2YXYs9iq2LTZgdmJINin2YTYs9mK2K_YqSDYrtiv2YrYrNipINin2YTZg9io2LHZiQ!5e0!3m2!1sar!2siq!4v1717539179493!5m2!1sar!2siq" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6753.431736140664!2d44.689305206233186!3d32.29724113342761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1558ebdaca058c79%3A0x841f16eb430f0a36!2z2YXYs9iq2LTZgdmJINiu2K_Zitis2Ycg2KfZhNmD2KjYsdmJ!5e0!3m2!1sar!2siq!4v1703036130717!5m2!1sar!2siq" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->

    <v-footer class="primary white--text">
        <v-row justify="center" align="center" class="mx-12">
            <v-avatar tile size='150' class="mt-1">
                <v-img contain src="@/assets/images/logo-white.svg"></v-img>
            </v-avatar>
            <v-spacer />
            <v-col class="text-left">
                <!-- <h3>07744700012</h3>
                <h3>07744700013</h3> -->
                <h3 >
                    <span @click="makePhoneCall()" class="cur-pointer" dir="ltr">5055</span>

                    <v-icon class="mr-2" color="white">
                        fi fi-rr-phone-flip
                    </v-icon>
                </h3>
            </v-col>
        </v-row>
    </v-footer>
</div>
</template>

<script>
export default {
    // components:{
    //     Album: () => import("../../components/Album.vue")
    // },
    data() {
        return {
            valid: true,
            message: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                details: '',
            },
        }
    },
    methods: {
        makePhoneCall() {
            window.open("tel:5055");
        },
        required() {
            return (v) => !!v || 'هذا الحقل مطلوب'
        },
        sendMessage() {
            this.$global.state.loading = true
            this.$axios.post('/api/contact', this.message).then((res) => {
                this.$global.state.loading = false
                this.$global.methods.toast('تم ارسال الرسالة بنجاح')
                this.$router.push('/')
            }).catch((err) => {
                this.$global.state.loading = false
                this.$global.methods.toast('حدث خطأ ما')
            })
        },
    },
}
</script>
